import {
  escapeRegExp,
  isEmpty,
  get,
  hasIn,
  merge,
  capitalize,
} from 'lodash';
import moment from 'moment-timezone';

import DateUtils from '@/components/renderer/mixins/DateUtils';
import { FIELD_DEFAULT_REPLACE_WITH_FIRST } from '@/constants/field';
import { RuleType } from '@/constants/rules';
import { Joi } from '@/lib/joi';
import { buildInputFromField } from '@/lib/schema-helper';
import { eventBus } from '@/store/bus';
import store from '@/store/index';
import FieldOperators from '@/util/FieldOperators';

class Field {
  // TODO: refactor to just take objectKey
  constructor(field, object) {
    this.objectKey = object.key;
    this.setField(field);
  }

  setField(field) {
    // update field connection names after settings save
    this.setConnectionNamesForField(field);

    if (!field.format) {
      field.format = {};
    }

    this.attributes = { ...this.fieldDefault(), ...field };
  }

  setConnectionNamesForField(field) {
    if (field.type === 'connection') {
      const { activeObject } = store.getters;

      if (!activeObject) {
        this.attributes = { ...this.fieldDefault(), ...field };

        return;
      }

      activeObject.connections.outbound.forEach((conn) => {
        if (conn.key !== field.key) {
          return;
        }

        conn.name = field.name;
      });

      activeObject.connections.inbound.forEach((conn) => {
        if (conn.key !== field.key) {
          return;
        }

        conn.name = field.name;
      });

      activeObject.setConnections();
    }
  }

  fieldDefault() {
    return {
      conditional: false,
      format: {},
      key: 'new',
      name: 'New field',
      required: false,
      rules: [],
      type: 'short_text',
      unique: false,
      validation: null,
      meta: {},
    };
  }

  ensureUniqueName() {
    let newFieldName = this.name;

    let sameNamesCount = 1;
    let checkForMatch = true;

    while (checkForMatch) {
      // only append a number to the name to compare if greater than 1
      const nameToCompare = (sameNamesCount === 1) ? newFieldName : `${newFieldName} ${String(sameNamesCount)}`;

      // does name already exist
      if (this.parentObject.fields.find((field) => field.name === nameToCompare)) {
        // iterate count so we can also check against further numbers e.g. Contacts 2, Contacts 3
        sameNamesCount++;
      } else {
        // no match found, so use this
        newFieldName = nameToCompare;

        // we're done!
        checkForMatch = false;
      }
    }

    this.name = newFieldName;
  }

  // GETTERS, SETTERS, ATTRIBUTES, RAW
  get name() {
    return this.attributes.name;
  }

  set name(newName) {
    this.attributes.name = newName;
  }

  get key() {
    return this.attributes.key;
  }

  get type() {
    return this.attributes.type;
  }

  get immutable() {
    return this.attributes.immutable;
  }

  get conditional() {
    return this.attributes.conditional;
  }

  get relationship() {
    return this.attributes.relationship;
  }

  get validation() {
    return this.attributes.validation;
  }

  get validationCount() {
    return isEmpty(this.attributes.validation) ? 0 : this.attributes.validation.length;
  }

  get rules() {
    return this.attributes.rules || [];
  }

  get rulesCount() {
    return isEmpty(this.attributes.rules) ? 0 : this.attributes.rules.length;
  }

  set type(type) {
    this.attributes.type = type;
  }

  get format() {
    return this.attributes.format;
  }

  get parentObject() {
    return store.getters.getObject(this.objectKey);
  }

  set format(format) {
    this.attributes.format = format;
  }

  get hasRequiredSettings() {
    // All formulas require you to choose field to aggregate
    if (this.isFormula()) {
      return true;
    }

    // Equation need configured
    if (this.type === window.Knack.config.EQUATION) {
      return true;
    }

    return false;
  }

  get meta() {
    return this.attributes.meta;
  }

  get(property) {
    // Using lodash get() so property can contain a nested path e.g. `details.columns`
    return get(this.attributes, property);
  }

  set(property, newVal) {
    this.attributes[property] = newVal;
  }

  raw() {
    return this.attributes;
  }

  edit() {
    if (!this.edit) {
      this.edit = { ...this.attributes };
    }

    return this.edit;
  }

  /**
   * Uses a view item (like a search input) to override a field format to be used by an input component
   * E.g. search inputs format a multiple choice to be a select box or checkboxes
   *
   * @param {object} item - a view item, like a search input
   * @returns {format}
   */
  setFormatForSearchInput(item) {
    const format = _.cloneDeep(this.format);

    // Currently only used for multiple choice fields
    if (this.type !== 'multiple_choice') {
      return format;
    }

    // Set the format type to the search input
    format.type = item.multi_input;

    if (item?.multi_input === 'chosen') {
      format.type = 'dropdown';
    }

    if (item?.multi_type === 'many' && item?.multi_input === 'chosen') {
      format.type = 'multi';
    }

    return format;
  }

  /**
   * Check if given field keys trigger this field's conditional rules
   * @param {Array} of potential field keys to check for triggers
   * @returns {Array} of objects storing field keys and type
  */
  getTriggersForConditionalRules(triggeringFieldKeys) {
    const triggers = [];

    this.get('rules')?.forEach((rule) => {
      // Check criteria
      rule.criteria.forEach((criteria) => {
        if (triggeringFieldKeys.includes(criteria.field)) {
          triggers.push({
            key: criteria.field,
            type: 'criteria',
          });
        }
      });

      // Also want to check values
      rule.values.forEach((value) => {
        if (value.type === 'record' && triggeringFieldKeys.includes(value.input)) {
          triggers.push({
            key: value.input,
            type: 'value',
          });
        }
      });
    });

    return triggers;
  }

  /**
   * Check if given field keys trigger this field's validation rules
   * @param {Array} an array of potential field keys to check for triggers
   * @returns {Array} a array of field keys
  */
  getTriggersForValidationRules(triggeringFieldKeys) {
    const triggers = [];

    this.get('validation')?.forEach((validation) => {
      validation.criteria.forEach((criteria) => {
        if (triggeringFieldKeys.includes(criteria.field)) {
          triggers.push({
            key: criteria.field,
            type: 'criteria',
          });
        }
      });
    });

    return triggers;
  }

  /**
   * Check if given field keys trigger this field's equation
   * @param {Array} an array of potential field keys to check for triggers
   * @returns {Array} a array of field keys
  */
  getTriggersForEquation(triggeringFieldKeys) {
    const triggers = [];

    triggeringFieldKeys.forEach((fieldKey) => {
      const regex = new RegExp(`\\{${escapeRegExp(fieldKey)}\\}`);
      if (regex.test(this.get('format.equation'))) {
        triggers.push({
          key: fieldKey,
          type: 'equation',
        });
      }
    });

    return triggers;
  }

  /**
   * Check if given field keys trigger this field's formula
   * @param {Array} an array of potential field keys to check for triggers
   * @returns {Array} a array of field keys
  */
  getTriggersForFormula(triggeringFieldKeys) {
    const triggers = [];

    if (triggeringFieldKeys.includes(this.get('format.field.key'))) {
      triggers.push({
        key: this.get('format.field.key'),
        type: 'field',
      });
    }

    // Also check formula filters
    this.get('format.filters')?.forEach((filter) => {
      if (triggeringFieldKeys.includes(filter.field)) {
        triggers.push({
          key: filter.field,
          type: 'filter',
        });
      }
    });

    return triggers;
  }

  getConnectedObject() {
    if (!this.relationship) {
      return null;
    }

    return store.getters.getObject(this.relationship.object);
  }

  getConnectedDisplayFieldKey() {
    const connectedObject = this.getConnectedObject();

    return connectedObject.identifier;
  }

  // VALIDATE METHODS
  validate(validateAttributes = {}) {
    const toValidate = { ...this.attributes, ...validateAttributes };

    const validationSchema = {
      _id: Joi.string().optional(),
      name: Joi.string().invalid(this.parentObject.fields.filter((field) => field.key !== this.key).map((field) => field.name)).required(),
      type: Joi.string().required(),
      key: Joi.string().empty('').optional(), // not required because undefined before create
      object_key: Joi.string(),
      format: this.formatValidation(toValidate),
      relationship: Joi.object(),
      default: Joi.any(),
      required: Joi.boolean(),
      unique: Joi.boolean(),
      user: Joi.boolean(),
      conditional: Joi.boolean(),
      rules: Joi.array().allow(null),
      validation: Joi.array().allow(null).items(Joi.object({
        message: Joi.string().required(),
        criteria: Joi.array(),
      })),
      meta: Joi.object(),
    };

    const validationOptions = {
      abortEarly: false,
      allowUnknown: true,
    };

    const validationResult = Joi.validate(toValidate, validationSchema, validationOptions);

    return {
      error: validationResult.error,
      errorMap: {
        'error.format_field_key.any.empty': 'Select a field for this formula.',
        'error.name.any.empty': 'Give your field a name.',
        'error.name.any.invalid': '<b><%= context.value %></b> is already being used by another field. Please use a unique name.',
      },
    };
  }

  formatValidation(toValidate) {
    if (['sum', 'max', 'min', 'average', 'count'].includes(toValidate.type)) {
      const keyValidator = Joi.object({
        key: Joi.string().required(),
      });

      return Joi.object({
        field: keyValidator,
      }).unknown();
    }

    if (toValidate.type === 'equation' || toValidate.type === 'concatenation') {
      const equationToValidate = merge(this, toValidate);

      return Joi.object().validEquation(equationToValidate);
    }

    return Joi.object();
  }

  // API METHODS

  async create(createAttributes = {}, fieldIndex) {
    const toCreate = { ...this.attributes, ...createAttributes };

    const {
      name, type, required, unique, format, relationship, validation, meta,
    } = toCreate;
    const defaultValue = toCreate.default;

    const response = await window.Knack.Api.createField(this.objectKey, name, type, required, unique, defaultValue, format, relationship, validation, fieldIndex, meta);

    this.setField(response.field);

    // Commit this field to the object owner
    store.getters.getObject(this.objectKey).addField(this, fieldIndex);

    // Update connections ?

    eventBus.$emit('field.create', this);
    eventBus.$emit(`objects.${this.objectKey}.fields.create`, this);

    return response;
  }

  // attributes param allows for non-reactive updates
  async update(updateAttributes = {}) {
    const toUpdate = { ...this.attributes, ...updateAttributes };

    const {
      name, type, required, unique, format, validation, rules, relationship, meta,
    } = toUpdate;

    const defaultValue = updateAttributes.default;

    const response = await window.Knack.Api.updateField(this.objectKey, this.key, name, type, required, unique, defaultValue, format, rules, validation, relationship, meta);

    this.setField(response.field);

    eventBus.$emit('field.update', this);
    eventBus.$emit(`objects.${this.objectKey}.fields.${this.key}.update`, this);

    return response;
  }

  // META PROPERTIES

  // These fields don't use the simple default value in field settings
  hasDefaultValue() {
    const fieldsNotDefault = [
      'address',
      'auto_increment',
      'boolean',
      'concatenation',
      'date_time',
      'email',
      'equation',
      'file',
      'image',
      'multiple_choice',
      'name',
      'phone',
      'rating',
      'signature',
      'text_formula',
      'timer',
      'min',
      'max',
      'average',
      'count',
      'sum',
    ];

    return !fieldsNotDefault.includes(this.type);
  }

  canBeUnique() {
    const fieldsNotUnique = [
      'address',
      'auto_increment',
      'average',
      'boolean',
      'concatenation',
      'connection',
      'count',
      'date_time',
      'equation',
      'file',
      'image',
      'max',
      'min',
      'multiple_choice',
      'name',
      'rating',
      'signature',
      'sum',
      'timer',
      'user_roles',
    ];

    return !fieldsNotUnique.includes(this.type);
  }

  // Convenience functions for checking if field is a specific type
  // ToDo: Standardize isTypeX. Type is more explict so it's not confused with a generic data type like date.
  isTypeImage() {
    return (this.type === window.Knack.config.IMAGE);
  }

  isConnection() {
    return (this.type === window.Knack.config.CONNECTION);
  }

  isDate() {
    return (this.type === window.Knack.config.DATE_TIME);
  }

  isAddress() {
    return (this.type === window.Knack.config.ADDRESS);
  }

  isParagraph() {
    return (this.type === window.Knack.config.PARAGRAPH_TEXT);
  }

  isBoolean() {
    return this.type === window.Knack.config.BOOLEAN;
  }

  isPassword() {
    return this.type === window.Knack.config.PASSWORD;
  }

  isNumeric() {
    return window.Knack.config.fields[this.type].numeric;
  }

  isTimeOnly() {
    if (!this.format.date_format) {
      return false;
    }

    return this.format.date_format === 'Ignore Date';
  }

  isComplexValue() {
    const { config } = window.Knack;

    const complexFields = [
      config.ADDRESS,
      config.CONNECTION,
      config.DATE_TIME,
      config.EMAIL,
      config.FILE,
      config.IMAGE,
      config.NAME,
      config.LINK,
      config.PHONE,
      config.SIGNATURE,
      config.TIMER,
    ];

    return complexFields.includes(this.type);
  }

  isSimpleValue() {
    return !this.isComplexValue();
  }

  isFormula() {
    return window.Knack.config.fields[this.type].group === 'formula';
  }

  // any types that store values that can be used as dates
  storesDateValues() {
    if (this.isDate()) {
      return true;
    }

    // equations formatted to store dates
    if (this.isDateEquation()) {
      return true;
    }

    return false;
  }

  isDateEquation() {
    if (this.type === window.Knack.config.EQUATION && this.format.equation_type === 'date' && this.format.date_result === 'date') {
      return true;
    }

    return false;
  }

  // any types that store values that can be used as numbers
  storesNumericValues() {
    // make sure equations are not formatted to store dates
    if (this.type === window.Knack.config.EQUATION) {
      if (this.format.equation_type === 'date' && this.format.date_result === 'date') {
        return false;
      }

      return true;
    }

    return window.Knack.config.fields[this.type].numeric;
  }

  rendersAsRating() {
    if (this.type === window.Knack.config.RATING) {
      return true;
    }

    // formula fields can operate on ratings
    if (this.isFormula()) {
      // check if the field the formula is operating on is rating
      const connectedField = this.getFormulaConnectedField();

      if (connectedField && connectedField.rendersAsRating()) {
        return true;
      }
    }

    return false;
  }

  getFormulaConnectedField() {
    if (!hasIn(this.attributes, 'format.field')) {
      return null;
    }

    return store.getters.getField(this.get('format').field.key);
  }

  // This type maps to an input component and can be added to forms and updated with inputs
  isFormInput() {
    if (window.Knack.config.fields[this.type].input_type === 'none') {
      return false;
    }

    return true;
  }

  /**
   * Returns the input type that maps to a form input component.
   * Formulas and equations don't have form inputs that map to their field type. These can
   * be used in criteria as comparative values so we need to translate them.
   *
   * @returns {String} type
   */
  getFormInputType() {
    if (this.isFormInput()) {
      return this.type;
    }

    // Make sure all formulas can display as a number input
    if (this.isNumeric()) {
      return window.Knack.config.NUMBER;
    }

    // Make sure date equations can display as a date input
    if (this.isDateEquation()) {
      return window.Knack.config.DATE_TIME;
    }

    // Make sure text formulas can display as a text input
    if (this.type === window.Knack.config.CONCATENATION) {
      return window.Knack.config.SHORT_TEXT;
    }

    // Default just in case
    return this.type;
  }

  getAsFormInput() {
    if (this.isFormInput()) {
      return buildInputFromField(this);
    }

    return {};
  }

  isReadOnly() {
    return this.get('read_only');
  }

  isImportMatchable() {
    const { config } = window.Knack;

    const nonMatchableFields = [
      config.SIGNATURE,
      config.DATE_TIME,
      config.TIMER,
      config.ADDRESS,
      config.FILE,
      config.IMAGE,
      config.ADDRESS,
      config.NAME,
    ];

    return !nonMatchableFields.includes(this.type);
  }

  hasParts() {
    return this.type === window.Knack.config.NAME || this.type === window.Knack.config.ADDRESS || this.type === window.Knack.config.LINK;
  }

  hasThumbs() {
    return this.type === window.Knack.config.IMAGE && _.has(this.attributes, 'format.thumbs');
  }

  canBeADisplayField() {
    const nonDisplayFields = [
      'image',
      'file',
      'signature',
      'rating',
    ];

    if (nonDisplayFields.includes(this.type)) {
      return false;
    }

    return true;
  }

  canBeASortField() {
    const nonDisplayFields = [
      'signature',
      'rating',
    ];

    if (nonDisplayFields.includes(this.type)) {
      return false;
    }

    return true;
  }

  // Shows conditional and validation rules in the field settings
  canUseRules() {
    // E-Commerce fields can't use rules
    if (this.get('charge_key') || this.get('ecommerce')) {
      return false;
    }

    // Nor these field types
    const fieldTypesWithoutRules = [
      'auto_increment',
      'average',
      'concatenation',
      'count',
      'equation',
      'max',
      'min',
      'sum',
    ];

    if (fieldTypesWithoutRules.includes(this.type)) {
      return false;
    }

    // All other fields can use rules
    return true;
  }

  hasConditionalRules() {
    if (this.rules && this.rules.length && this.rules.conditional !== false) {
      return true;
    }

    return false;
  }

  canBeImported() {
    const { Knack } = window;

    // conditinoal rule can't be imported
    if (this.hasConditionalRules()) {
      return false;
    }

    // nor formulas
    if (this.isFormula()) {
      return false;
    }

    // nor these specific field types
    const fieldTypes = [
      Knack.config.AUTO_INCREMENT,
      Knack.config.SIGNATURE,
      Knack.config.FILE,
      Knack.config.EQUATION,
      Knack.config.CONCATENATION,
    ];

    return !fieldTypes.includes(this.type);
  }

  canBeUsedInEquations(equationType) {
    // any multiple choice can be used in numeric or text equations (v2 parity)
    // In addition, booleans can always be used for conditionals
    if (this.type === window.Knack.config.MULTIPLE_CHOICE || this.isBoolean()) {
      return true;
    }

    if (equationType === 'text') {
      return this.canBeUsedInTextFormulas(equationType);
    }

    if (equationType === 'date') {
      return (this.type === window.Knack.config.DATE_TIME || this.isNumeric());
    }

    return this.storesNumericValues() || this.storesDateValues() || this.isBoolean();
  }

  canBeUsedInTextFormulas(equationType) {
    if (equationType && equationType !== 'text') {
      return false;
    }

    const { Knack } = window;
    const includeTypes = [
      Knack.config.ADDRESS,
      Knack.config.BOOLEAN,
      Knack.config.CONCATENATION,
      Knack.config.DATE_TIME,
      Knack.config.EMAIL,
      Knack.config.EQUATION,
      Knack.config.LINK,
      Knack.config.MULTIPLE_CHOICE,
      Knack.config.NAME,
      Knack.config.PARAGRAPH_TEXT,
      Knack.config.PHONE,
      Knack.config.RATING,
      Knack.config.RICH_TEXT,
      Knack.config.SHORT_TEXT,
      Knack.config.TIMER,
    ];

    return (this.isNumeric() || includeTypes.includes(this.type));
  }

  isConnectedToUserRole() {
    if (!this.isConnection()) {
      return false;
    }

    return this.getConnectedObject().profile_key !== undefined;
  }

  // GET METHODS

  // applicable to yes_no fields only
  getBooleanLabel(value) {
    if (typeof value === 'string') {
      value = Boolean(value === 'true');
    }

    return (value) ? this.getBooleanTrueLabel() : this.getBooleanFalseLabel();
  }

  getBooleanTrueLabel() {
    return capitalize(this.format.format.split('_')[0]);
  }

  getBooleanFalseLabel() {
    return capitalize(this.format.format.split('_')[1]);
  }

  getDefaultValue() {
    let defaultValue = '';

    if (this.format && typeof this.format.default !== 'undefined') {
      defaultValue = this.format.default;
    } else if (typeof this.get('default') !== 'undefined') {
      defaultValue = this.get('default');
    }

    // ADDRESS: has no default option, so return empty schema
    if (!defaultValue && this.type === 'address') {
      if (this.format.input === 'lat_long') {
        defaultValue = {
          latitude: '',
          longitude: '',
        };
      }

      if (this.format.format === 'US' && this.format.input === 'address') {
        defaultValue = {
          street: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
        };
      }

      if (this.format.format === 'international' && this.format.input === 'address') {
        defaultValue = {
          street: '',
          street2: '',
          city: '',
          province: '',
          postal_code: '',
        };
      }

      if (this.format.format === 'international_country' && this.format.input === 'address') {
        defaultValue = {
          street: '',
          street2: '',
          city: '',
          province: '',
          postal_code: '',
          country: '',
        };
      }
    }

    // CONNECTION: can default to first option or an empty array
    if (!defaultValue && this.type === 'connection') {
      // first: we're setting a placeholder here for the connection input to handle,
      // otherwise we'd need to make an API request to get the first value and assign it.
      defaultValue = (this.format?.conn_default === 'first') ? [FIELD_DEFAULT_REPLACE_WITH_FIRST] : [];
    }

    // DATE_TIME: has no default option, so set defaults
    if (!defaultValue && this.type === 'date_time') {
      const time = moment();
      const toTime = moment().add(1, 'hours');

      defaultValue = {
        all_day: '',
        repeat: '',
      };

      if (this.format.date_format !== 'Ignore Date') {
        defaultValue.date = this.format.default_type !== 'none' ? DateUtils.methods.getFormattedDefaultDate(this) : '';
      }

      if (this.format.time_format !== 'Ignore Time') {
        if (this.format.time_type === 'current') {
          defaultValue.hours = DateUtils.methods.pad(DateUtils.methods.parsedHours(time, this.format.time_format), 2);
          // eslint-disable-next-line max-len
          defaultValue.minutes = DateUtils.methods.pad(DateUtils.methods.parsedMinutes(time, this.format.time_format), 2);
        } else if (this.format.default_time) {
          // eslint-disable-next-line max-len
          defaultValue.hours = DateUtils.methods.pad(DateUtils.methods.parsedHours(this.format.default_time, this.format.time_format), 2);
          // eslint-disable-next-line max-len
          defaultValue.minutes = DateUtils.methods.pad(DateUtils.methods.parsedMinutes(this.format.default_time, this.format.time_format), 2);
        }
        if (this.format.time_type === 'none') {
          defaultValue.hours = '';
          defaultValue.minutes = '';
        }
      }

      if (this.format.calendar) {
        defaultValue.to = {
          date: this.format.default_type !== 'none' ? DateUtils.methods.getFormattedDefaultDate(this) : '',
          hours: this.format.time_type !== 'none' ? DateUtils.methods.pad(DateUtils.methods.parsedHours(toTime, this.format.time_format), 2) : '',
          minutes: this.format.time_type !== 'none' ? DateUtils.methods.pad(DateUtils.methods.parsedMinutes(toTime, this.format.time_format), 2) : '',
        };
      }
    }

    // EMAIL: set the default as the email part unless it's already set
    if (defaultValue && this.type === 'email' && !defaultValue.email) {
      defaultValue = {
        email: defaultValue,
      };
    }

    // FILE:
    if (!defaultValue && this.type === 'file') {
      defaultValue = {};
    }

    // IMAGE:
    if (!defaultValue && this.type === 'image') {
      switch (this.format.source) {
        case 'url':
          defaultValue = '';
          break;

        case 'upload':
          defaultValue = {};
          break;
      }
    }

    // LINK: set the default as the URL part unless it's already set
    if (defaultValue && this.type === 'link' && !defaultValue.url) {
      defaultValue = {
        label: '',
        url: defaultValue,
      };
    }

    // NAME: has no default option, so return empty schema
    if (!defaultValue && this.type === 'name') {
      defaultValue = {
        title: '',
        first: '',
        middle: '',
        last: '',
      };
    }

    // PASSWORD: has no default option, so return empty schema
    if (!defaultValue && this.type === 'password') {
      defaultValue = {
        password: '',
        password_confirmation: '',
      };
    }

    // TIMER: has no default option, so set defaults
    if (!defaultValue && this.type === 'timer') {
      const date = moment().format('MM/DD/YYYY'); // initial date needs to be initialized as a regular unformatted US date
      const fromTime = moment();
      const toTime = moment().add(1, 'hours');

      defaultValue = {
        times: [
          {
            from: {
              date,
              hours: fromTime.hours(),
              minutes: fromTime.minutes(),
              am_pm: fromTime.format('A'),
            },
            to: {
              date,
              hours: toTime.hours(),
              minutes: toTime.minutes(),
              am_pm: toTime.format('A'),
            },
          },
        ],
      };
    }

    if (!defaultValue && this.type === 'equation') {
      defaultValue = 0;
    }

    return defaultValue;
  }

  getThumbs() {
    return (this.hasThumbs()) ? this.format.thumbs : [];
  }

  getNameWithThumb(thumb) {
    return `${this.name}:${this.getThumbName(thumb)}`;
  }

  getThumbName(thumb) {
    if (thumb.thumb_type === 'percentage') {
      return `${thumb.percentage}%`;
    }

    if (thumb.thumb_type === 'square') {
      return `${thumb.length}px square`;
    }

    return `${thumb.width}x${thumb.height}`;
  }

  getThumbNameFromKey(thumbKey) {
    if (thumbKey.indexOf(':') > -1) {
      thumbKey = thumbKey.split(':')[1];
    }

    const thumb = this.getThumbs().find((thumb) => thumb.key === thumbKey);

    if (!thumb) {
      return '';
    }

    return this.getThumbName(thumb);
  }

  getThumbsAsFieldOptions() {
    return this.getThumbs().map((thumb) => ({
      key: `${this.key}:${thumb.key}`,
      name: `${this.name} - ${this.getThumbName(thumb)}`,
      type: this.type,
      thumbKey: thumb.key,
    }));
  }

  // used to ensure the format will work with rules and filters with minimal space
  getMinimalFormat() {
    const format = _.cloneDeep(this.format);

    switch (this.type) {
      case 'email':

        format.text_format = 'same';
        break;

      case 'link':

        format.text_format = 'same';
        break;

      case window.Knack.config.MULTIPLE_CHOICE:

        if (format.type === 'multi') {
          return format;
        }

        format.type = 'single';
        break;
    }

    return format;
  }

  // for rules and criteria that allow field values to be set, this function ensures that any available fields are compatible with the recipient field type
  getCompatibleFieldOptions(options) {
    const { Knack } = window;

    return options.filter((option) => {
      // get the field for this option
      let fieldKey = option.value;

      // check for connected field
      if (fieldKey.indexOf('-') > -1) {
        // use the second (the first value is the connection field)
        fieldKey = fieldKey.split('-')[1];
      }

      const field = store.getters.getField(fieldKey);

      if (!field) {
        return false;
      }

      // compare the option field type with this field's type
      switch (this.type) {
        case Knack.config.CONNECTION:

          if (field.type === this.type && this.get('relationship').object === field.get('relationship').object) {
            return true;
          }

          return false;

        case Knack.config.ADDRESS:

          return (this.type === field.type);

        case Knack.config.SIGNATURE:

          return (this.type === field.type);

        case Knack.config.NAME:

          return (this.type === field.type);

        case Knack.config.TIMER:

          return (this.type === field.type);

        case Knack.config.IMAGE:

          // first allow links to work with images that are displaying from URLs
          if (this.type === Knack.config.IMAGE && this.format && this.format.source && this.format.source === 'url' && field.type === Knack.config.LINK) {
            return true;
          }

          return (field.type === Knack.config.IMAGE || field.type === Knack.config.FILE || field.type === Knack.config.CONCATENATION);

        case Knack.config.FILE:

          // first allow links to work with images that are displaying from URLs
          if (this.type === Knack.config.IMAGE && this.format && this.format.source && this.format.source === 'url' && field.type === Knack.config.LINK) {
            return true;
          }

          return (field.type === Knack.config.IMAGE || field.type === Knack.config.FILE || field.type === Knack.config.CONCATENATION);

        default:

          // Everything else goes!
          // We used to prevent connections here, but for v2 parity we're allowing. Ideally we're validating the display field for connections
          return true;
      }
    });
  }

  getSortAscending() {
    const { Knack } = window;
    const sortText = 'alphabetically from A to Z';
    const sorts = {};

    sorts[Knack.config.BOOLEAN] = 'in order from false to true';
    sorts[Knack.config.SIGNATURE] = 'in order from exists to blank';
    sorts[Knack.config.DATE_TIME] = 'chronologically from oldest to newest';

    // other number
    sorts[Knack.config.NUMBER] = 'numerically from low to high';
    sorts[Knack.config.RATING] = sorts[Knack.config.TIMER] = sorts[Knack.config.CURRENCY] = sorts[Knack.config.AUTO_INCREMENT] = sorts[Knack.config.SUM] = sorts[Knack.config.MIN] = sorts[Knack.config.MAX] = sorts[Knack.config.AVERAGE] = sorts[Knack.config.EQUATION] = sorts[Knack.config.COUNT] = sorts[Knack.config.NUMBER];

    return sorts[this.type] || sortText;
  }

  getSortDescending() {
    const { Knack } = window;
    const sortText = 'alphabetically from Z to A';
    const sorts = {};

    sorts[Knack.config.BOOLEAN] = 'in order from true to false';
    sorts[Knack.config.SIGNATURE] = 'in order from blank to exists';
    sorts[Knack.config.DATE_TIME] = 'chronologically from newest to oldest';

    // other number
    sorts[Knack.config.NUMBER] = 'numerically from high to low';
    sorts[Knack.config.RATING] = sorts[Knack.config.TIMER] = sorts[Knack.config.CURRENCY] = sorts[Knack.config.AUTO_INCREMENT] = sorts[Knack.config.SUM] = sorts[Knack.config.MIN] = sorts[Knack.config.MAX] = sorts[Knack.config.AVERAGE] = sorts[Knack.config.EQUATION] = sorts[Knack.config.COUNT] = sorts[Knack.config.NUMBER];

    return sorts[this.type] || sortText;
  }

  getFilterDefault() {
    return ``;
  }

  // TODO: replace other this.default() usages
  getDefaultRule(options = {
    isFilter: true,
  }) {
    const rule = {
      field: this.key,
      value: this.getFilterDefault(),
    };

    if (options.isFilter) {
      rule.operator = this.getFirstRuleOperator();
    }

    if (options.isValue) {
      rule.type = 'value';
    }

    return rule;
  }

  getRuleOperators(ruleType = '') {
    const options = {};

    // Only certain rules show change operators
    if ([
      RuleType.Record,
      RuleType.Submit,
    ].includes(ruleType)) {
      options.includeChangeOperators = true;
    }

    if ([
      RuleType.Filter,
    ].includes(ruleType)) {
      options.includeGeoOperators = true;
    }

    return FieldOperators.getOperators(this, options);
  }

  getValidationOperators(ruleType = '') {
    const options = {
      includeValidationOperators: true,
    };

    if ([
      RuleType.Display,
      RuleType.Task,
      RuleType.Action,
    ].includes(ruleType)) {
      options.includeChangeOperators = false;
    }

    return FieldOperators.getOperators(this, options);
  }

  getFirstRuleOperator() {
    return FieldOperators.getFirstOperator(this);
  }
}

export default Field;
