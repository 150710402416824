<template>
  <div>
    <p class="text-default text-base mb-0">
      Click below to run this task immediately. The task will continue to run on the schedule you've defined.
    </p>

    <div
      v-if="hasExceededRateLimit"
      class="error-alert form-errors rounded-lg p-4 bg-destructive mt-3 border-0"
    >
      <div class="mb-1">
        <Icon
          class="block text-destructive-emphasis h-4 w-4"
          type="exclamation-triangle"
        />
      </div>
      <b class="text-destructive-emphasis text-base">
        Limit of runs per task per day reached
      </b>
      <p class="text-destructive-emphasis text-base">
        You have exceeded the maximum number of runs for this task for today. Please try again later.
        For more information, please review our
        <a
          href="https://learn.knack.com/article/hj38ebv4di-scheduled-tasks#task_limits"
          target="_blank"
          class="underline text-default font-medium"
        >Support documentation</a>.
      </p>
    </div>

    <div class="submit-buttons flex justify-end">
      <a
        class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium"
        data-cy="task-run"
        @click="onClickRun"
      >
        Run this task
      </a>
    </div>
  </div>
</template>
<script>
import FieldUtils from '@/components/fields/FieldUtils';
import Icon from '@/components/ui/Icon';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  components: {
    Icon,
  },
  computed: {
    backLink() {
      return `/tasks/objects/${this.object.key}`;
    },
  },
  data() {
    return {
      hasExceededRateLimit: false,
    };
  },
  methods: {
    onClickRun() {
      this.commitRequest({
        request: () => window.Knack.Api.runTask(this.$route.params.taskKey),
        onSuccess: () => {
          this.$router.push(this.backLink);
          if (this.hasExceededRateLimit) {
            this.hasExceededRateLimit = false;
          }
        },
        onError: (errors, status) => {
          if (status === 429) {
            this.hasExceededRateLimit = true;
          }
        },
      });
    },
  },
};
</script>
