const storeState = {
  assetIsLoaded: false,
  assetMetadata: {},
  assetPreviewSignedUrl: null,
};

const storeMutations = {
  resetAll(state) {
    state.assetPreviewSignedUrl = null;
    state.assetIsLoaded = false;
    state.assetMetadata = {};
  },
  setAssetMetadata(state, metadata) {
    state.assetMetadata = metadata;
  },
  setAssetPreviewSignedUrl(state, signedUrl) {
    state.assetPreviewSignedUrl = signedUrl;
  },
  setDownloadUrl(state, url) {
    state.downloadUrl = url;
  },
  setAssetIsLoaded(state, status) {
    state.assetIsLoaded = status;
  },
  setPreviewUrl(state, url) {
    state.previewUrl = url;
  },
};

const storeActions = {
  async setAssetMetadata({ state, commit }, {
    assetId, pageKey, viewKey, fieldKey,
  }) {
    try {
      const { asset } = await window.Knack.Api.getAssetMetadata(assetId, pageKey, viewKey, fieldKey);

      asset.pageKey = pageKey;
      asset.viewKey = viewKey;
      asset.fieldKey = fieldKey;

      commit('setAssetMetadata', asset);
    } catch (err) {
      throw err;
    }
  },
  async setAssetPreviewSignedUrl({ state, commit }, {
    assetId, pageKey, viewKey, fieldKey,
  }) {
    try {
      const { signed_url: signedUrl } = await window.Knack.Api.getAssetSignedUrl(
        assetId,
        pageKey,
        viewKey,
        fieldKey,
        true,
      );

      commit('setAssetPreviewSignedUrl', signedUrl);
    } catch (err) {
      throw err;
    }
  },
  async showAssetViewer({ state, commit, dispatch }, {
    assetId, pageKey, viewKey, fieldKey,
  }) {
    await dispatch('setAssetMetadata', {
      assetId,
      pageKey,
      viewKey,
      fieldKey,
    });

    await dispatch('setAssetPreviewSignedUrl', {
      assetId,
      pageKey,
      viewKey,
      fieldKey,
    });

    await commit('setAssetIsLoaded', true);
  },
};

const storeGetters = {
  assetIsLoaded: (state) => state.assetIsLoaded,
  assetPreviewSignedUrl: (state) => state.assetPreviewSignedUrl,
  assetMetadata: (state) => state.assetMetadata,
};

export default {
  namespaced: true,
  state: storeState,
  mutations: storeMutations,
  actions: storeActions,
  getters: storeGetters,
};
