<template>
  <div
    class="save-actions transition p-4 bg-muted border-t-0 h-[86px] flex justify-end"
  >
    <a
      class="save flex-none capitalize p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium order-2 h-10"
      data-cy="toolbox-save"
      :data-feature="dataFeature"
      data-feature-x-offset="-24"
      data-feature-y-offset="-24"
      @click="onSave"
    >
      save
    </a>
    <a
      class="cancel flex-none capitalize p-3 rounded-lg bg-transparent border-0 text-emphasis font-medium m-0 leading-4 order-1 h-10 mr-4"
      data-cy="toolbox-cancel"
      @click="onCancel"
    >
      cancel
    </a>
  </div>
</template>

<script>
import { trackIntercomEvent } from '@/lib/intercom-helper';

export default {
  props: {
    dataFeature: {
      type: String,
      default: '',
    },
  },
  emits: ['save', 'cancel'],
  methods: {
    onSave() {
      // we stored in the RulesList component whether a record or email rule was added
      // to track this event to intercom, as this component is so generic and accessing
      // the save event is not possible at that level.
      // these items will only live in the period where the user is adding a rule
      if (localStorage.getItem('record_rule_added')) {
        trackIntercomEvent('add_record_rule');
        localStorage.removeItem('record_rule_added');
      }
      if (localStorage.getItem('email_rule_added')) {
        trackIntercomEvent('add_email_rule');
        localStorage.removeItem('email_rule_added');
      }
      this.$emit('save');
    },
    onCancel() {
      localStorage.removeItem('record_rule_added');
      localStorage.removeItem('email_rule_added');
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.save-actions {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  display: flex;
  border-top: 1px solid #fff;
  left: 0;
  z-index: 10;

  a {
    display: block;
    line-height: 42px;
    flex: 0 1 50%;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;

    &.save {
      background-color: #f2731e;
      color: #fff;
    }

    &.cancel {
      background-color: #e1e1e2;
      color: #797b80;
    }
  }

  &.has-active-updates {
    bottom: 0;
  }
}
</style>
