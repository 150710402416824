import store from '@/store';

/**
 * Gets the live app's base url.
 *
 * @returns {string}
 */
export const getLiveAppBaseUrl = () => {
  const { accountSlug } = store.getters;

  return process.env.VUE_APP_LIVE_APP_URL.replace('{{account}}', accountSlug);
};

/**
 * Gets the live app url including the app and a page slug.
 *
 * @param {string} pageSlug
 * @returns {string}
 */
export const liveAppUrl = (pageSlug) => {
  const applicationSlug = store.getters.appSlug;
  const urlBase = getLiveAppBaseUrl();

  // this currently goes to the V2 renderer
  // when V3 renderer is ready to be tested this will need to change to
  // `//${accountSlug}.${baseUrl}/${applicationSlug}/pages/${pageSlug}`
  return `${urlBase}/${applicationSlug}#${pageSlug}`;
};

/**
 * Gets the current href as its dev equivalent.
 *
 * @returns {string}
 */
export const getDevUrl = () => {
  // This should not be affected by private clusters since this only used when reporting tickets.
  const devDomain = 'builder.knackcrm.com';

  return window.location.href.replace(/^(https?:\/\/)[^/]+(\/.+)$/i, `$1${devDomain}$2`);
};

/**
 * Gets the React builder base url including the account and app slugs.
 *
 * @returns {string}
 */
export const getBuilderNextBaseUrl = () => {
  let { accountSlug, appSlug } = store.getters;

  // If the account and app slugs are not available in the store
  // (e.g. the app is loading and the store is not initialized yet) try to extract them from the URL.
  if (!accountSlug && !appSlug) {
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    [accountSlug, appSlug] = pathSegments;
  }

  return `${process.env.VUE_APP_BUILDER_NEXT_URL}/${accountSlug}/${appSlug}`;
};

export const getAddTableUrl = () => `${getBuilderNextBaseUrl()}/tables/add?origin=v3`;

export const getDataModelUrl = () => `${getBuilderNextBaseUrl()}/data-model?origin=v3`;

export const getTableImportUrl = (objectKey) => `${getBuilderNextBaseUrl()}/tables/${objectKey}/import?origin=v3`;
