/**
 * Maps to FlagSmith ID
 * @const {string}
 */
// eslint-disable-next-line import/prefer-default-export
export const INTEGRATIONS = 'integrations';
export const FULL_NEXTGEN_ACCESS = 'full_nextgen_access';
export const ONLY_NEXTGEN_ACCESS = 'only_nextgen_access';
export const FLOWS = 'albato_flows';
export const AB_TESTING_OBJECT_TABS = 'ab_testing_object_tabs';
