<template>
  <div
    :key="`fieldListItem-${item.field}`"
    :class="shouldWrap ? `field-list-item-wrap` : ``"
    class="field-list-item group/shared grid grid-cols-2 gap-2"
  >
    <!-- Link Text Field -->
    <div
      v-if="showTextField"
      class="absolute"
      style="min-width: 55px; flex-grow: 0; text-align: center;"
    >
      <span class="mt-2.5">
        <input
          :value="item.text"
          type="text"
          @input="onItemUpdate('text', $event.target.value)"
        >
      </span>
    </div>

    <!-- Boolean Logic -->
    <div
      v-if="showBooleanOptions"
      class="absolute"
      style="min-width: 64px; flex-grow: 0; align-items: center;"
    >
      <b
        v-if="itemIndex === 0"
        class="text-default text-sm font-medium mt-2.5"
        style="display: block; margin-top: 6px;"
      >
        Where
      </b>
      <select
        v-else-if="itemIndex === 1"
        v-model="booleanMatchLocal"
        data-cy="field-list-group-operator"
        class="text-base py-2 pl-3 leading-5"
        @change="onUpdateBooleanMatch"
      >
        <option>and</option>
        <option>or</option>
      </select>
      <span v-else>
        {{ booleanMatchLocal }}
      </span>
    </div>

    <!-- Field -->
    <select
      v-if="showField"
      :value="item.field"
      class="field-list-field text-base py-2 pl-3 leading-5 mb-0 mr-0"
      :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
      data-cy="field-list-field"
      @change="onItemUpdate('field', $event.target.value)"
    >
      <option
        v-for="(fieldOption, index) in fieldOptions"
        :key="index"
        :value="fieldOption.value"
      >
        {{ fieldOption.label }}
      </option>
    </select>

    <!-- Operator -->
    <select
      v-if="showOperator"
      :value="item.operator"
      class="field-list-operator text-base py-2 pl-3 leading-5 mb-0 mr-0"
      data-cy="field-list-operator"
      @change="onItemUpdate('operator', $event.target.value)"
    >
      <option
        v-for="(operator, index) in operators"
        :key="index"
        :value="getOperatorValue(operator)"
        :disabled="operatorIsDisabled(operator)"
      >
        {{ getOperatorLabel(operator) }}
      </option>
    </select>

    <!-- Value -->
    <template v-if="isNotBlank">
      <select
        v-if="valueCanBeField"
        class="kn-input text-base py-2 pl-3 leading-5 m-0 col-span-2"
        :value="item.value_type"
        @change="onSelectFieldType"
      >
        <option
          value="custom"
          title="Custom Value"
        >
          Custom Value
        </option>
        <option
          value="field"
          title="Field Value"
        >
          Field Value
        </option>
      </select>

      <template v-if="item.value_type === 'field'">
        <select
          :value="item.value_field"
          class="text-base py-2 pl-3 leading-5 mb-0 col-span-2"
          @change="onItemUpdate('value_field', $event.target.value)"
        >
          <option
            v-for="(valueField, index) in object.getFieldOptions(getFieldOptionsOptions)"
            :key="index"
            :value="valueField.value"
          >
            {{ valueField.label }}
          </option>
        </select>
      </template>

      <template v-else-if="isSpecialValue">
        <template v-if="item.operator === 'near'">
          <input
            :value="item.value"
            type="text"
            data-cy="field-list-range-text"
            class="mb-0 col-span-2 mr-0"
            :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
            @input="onItemUpdate('value', $event.target.value)"
          >
          <span
            class="label m-0 text-default font-medium text-sm"
            :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
          >
            Range:
          </span>
          <select
            :value="item.range"
            name="range"
            class="text-base py-2 pl-3 leading-5 mb-0 col-span-2"
            :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
            data-cy="field-list-range"
            @change="onItemUpdate('range', $event.target.value)"
          >
            <option
              v-for="nearRange in nearRanges"
              :key="nearRange.value"
              :value="nearRange.value"
            >
              {{ nearRange.label }}
            </option>
          </select>
        </template>

        <template v-else-if="isSpecialDateRange">
          <select
            :value="item.range"
            name="range"
            class="text-base py-2 pl-3 leading-5 m-0"
            :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
            @change="onItemUpdate('range', $event.target.value)"
          >
            <option
              v-for="option in rangeOptions"
              :key="option"
            >
              {{ option }}
            </option>
          </select>
          <select
            :value="item.type"
            name="type"
            class="text-base py-2 pl-3 leading-5 mb-0"
            @change="onItemUpdate('type', $event.target.value)"
          >
            <option
              v-for="specialDateRangeType in specialDateRangeTypes"
              :key="specialDateRangeType"
            >
              {{ specialDateRangeType }}
            </option>
          </select>
        </template>

        <template v-else-if="isSpecialSize">
          <input
            :value="item.value"
            name="value"
            type="text"
            class="m-0"
            @input="onItemUpdate('value', $event.target.value)"
          >
          <span class="label hidden">&nbsp;</span>
          <select
            :value="item.unit"
            name="unit"
            class="text-base py-2 pl-3 leading-5 mb-0"
            @change="onItemUpdate('unit', $event.target.value)"
          >
            <option
              v-for="sizeUnitType in sizeUnits"
              :key="sizeUnitType"
            >
              {{ sizeUnitType }}
            </option>
          </select>
        </template>

        <template v-else-if="item.operator === 'is between days of the week'">
          <span
            class="min-label m-0 text-default font-medium text-sm col-span-2"
            style="margin-right: 0.25rem"
          >
            &nbsp;From:
          </span>
          <select
            :value="item.from_date"
            name="from_date"
            class="text-base py-2 pl-3 leading-5 mb-0 w-full col-span-2"
            style="width: 96px;"
            @change="onItemUpdate('from_date', $event.target.value)"
          >
            <option
              v-for="dayOfWeek in daysOfWeek"
              :key="dayOfWeek.value"
              :value="dayOfWeek.value"
            >
              {{ dayOfWeek.label }}
            </option>
          </select>
          <span
            class="min-label mb-0 text-default font-medium text-sm col-span-2"
            style="margin-right: 0.25rem"
          >
            &nbsp;To:
          </span>
          <select
            :value="item.to_date"
            name="to_date"
            style="width: 96px;"
            class="text-base py-2 pl-3 leading-5 mb-0 w-full col-span-2"
            @change="onItemUpdate('to_date', $event.target.value)"
          >
            <option
              v-for="dayOfWeek in daysOfWeek"
              :key="dayOfWeek.value"
              :value="dayOfWeek.value"
            >
              {{ dayOfWeek.label }}
            </option>
          </select>
        </template>

        <select
          v-else-if="item.operator === 'is during the current'"
          :value="item.type"
          name="type"
          class="text-base py-2 pl-3 leading-5 mb-0 col-span-2"
          :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
          @change="onItemUpdate('type', $event.target.value)"
        >
          <option
            v-for="currentDateRangeType in currentDateRangeTypes"
            :key="currentDateRangeType"
          >
            {{ currentDateRangeType }}
          </option>
        </select>

        <template v-else-if="item.operator === 'is between dates'">
          <span
            class="min-label m-0 text-default font-medium text-sm col-span-2"
            style="margin-right: 0.25rem"
          >
            From:&nbsp;
          </span>

          <DateInput
            :model-value="item.value.from_date"
            :input="input"
            :field="localField"
            :ignore-calendar-options="true"
            :ignore-time-options="true"
            :allows-any-date="true"
            :allows-any-time="true"
            class="kn-datepicker"
            name="from_date"
            style="max-width: 80px;"
            @update:model-value="onItemUpdate('value.from_date', $event)"
          />

          <span
            class="min-label mb-0 text-default font-medium text-sm col-span-2"
            style="margin-right: 0.25rem"
          >
            &nbsp;To:&nbsp;
          </span>

          <DateInput
            :model-value="item.value.to_date"
            :input="input"
            :field="localField"
            :ignore-calendar-options="true"
            :allows-any-date="true"
            :allows-any-time="true"
            class="kn-datepicker"
            name="to_date"
            style="max-width: 80px;"
            @update:model-value="onItemUpdate('value.to_date', $event)"
          />
        </template>

        <select
          v-else-if="item.operator === 'is a day of the week'"
          :value="item.value"
          name="value"
          class="text-base py-2 pl-3 leading-5 mb-0 col-span-2"
          @change="onItemUpdate('value', $event.target.value)"
        >
          <option
            v-for="dayOfWeek in daysOfWeek"
            :key="dayOfWeek.value"
            :value="dayOfWeek.value"
          >
            {{ dayOfWeek.label }}
          </option>
        </select>
      </template>

      <template v-else-if="useSimpleValueInputs">
        <input
          v-if="valueType === 'text'"
          :value="item.value"
          data-cy="field-list-text-input"
          type="text"
          class="mb-0 col-span-2"
          :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
          @input="onItemUpdate('value', $event.target.value)"
        >

        <div
          v-if="valueType === 'boolean'"
          class="col-span-2 m-0 max-w-full"
          :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
        >
          <BooleanInput
            :model-value="item.value"
            :input="input"
            :field="localField"
            :use-select-input="useSimplifiedInputs"
            @update:model-value="onItemUpdate('value', $event)"
          />
        </div>

        <div
          v-if="valueType === 'select'"
          class="col-span-2 m-0"
          :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
        >
          <MultipleChoiceInput
            :model-value="item.value"
            :input="input"
            :field="localField"
            :options="valueOptions"
            :use-select-input="useSimplifiedInputs"
            @update:model-value="onItemUpdate('value', $event)"
          />
        </div>

        <div
          v-if="valueType === 'date'"
          class="col-span-2 m-0"
          :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
        >
          <DateInput
            :model-value="item.value"
            :ignore-calendar-options="true"
            :ignore-time-options="input.format.time_format === 'Ignore Time'"
            :input="input"
            :field="localField"
            :numeric-time-format="false"
            :allows-any-date="true"
            :allows-any-time="true"
            @update:model-value="onItemUpdate('value', $event)"
          />
        </div>

        <div
          v-if="valueType === 'connection'"
          class="col-span-2 m-0"
          :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
        >
          <ConnectionInput
            v-model="connectionValue"
            :field="localField"
            :input-type="inputType"
            :use-select-input="useSimplifiedInputs"
            :multiple="fieldTypeIsMultiple"
          />
        </div>
      </template>

      <template v-else>
        <div
          class="col-span-2 m-0"
          :class="{ 'ml-[74px]': showTextField || showBooleanOptions }"
        >
          <ConnectionInput
            v-if="valueType === `connection`"
            v-model="connectionValue"
            :field="localField"
            :input-type="inputType"
            :use-select-input="useSimplifiedInputs"
            :multiple="fieldTypeIsMultiple"
          />
          <FormInput
            v-else
            :input="input"
            :field="localField"
            :show-label="false"
            :model-value="item.value"
            @update:model-value="onItemUpdate('value', $event)"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import castArray from 'lodash/castArray';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isSymbol from 'lodash/isSymbol';
import set from 'lodash/set';
import MultiSelect from 'vue-multiselect';

import FieldOperators from '@/util/FieldOperators';
import FormInput from '@/components/renderer/form/FormInput';
import ConnectionInput from '@/components/renderer/form/inputs/Connection';
import DateInput from '@/components/renderer/form/inputs/Date';
import MultipleChoiceInput from '@/components/renderer/form/inputs/MultipleChoice';
import BooleanInput from '@/components/renderer/form/inputs/Boolean';

const WRAP_TYPES = new Set([
  'connection',
  'select',
]);

const SPECIAL_VALUE_OPERATORS = new Set([
  'near',
  'is during the last',
  'is during the previous',
  'is during the next',
  'is before the previous',
  'is after the next',
  'is during the current',
  'size is less than',
  'size is greater than',
  'is a day of the week',
  'is between days of the week',
  'is between dates',
]);

const SPECIAL_DATE_RANGE_OPERATORS = new Set([
  'is during the last',
  'is during the previous',
  'is during the next',
  'is before the previous',
  'is after the next',
]);

const SPECIAL_SIZE_OPERATORS = new Set([
  'size is less than',
  'size is greater than',
]);

const COMPLEX_INPUT_VALUE_OPERATORS = new Set([
  'has changed to',
  'has changed from',
]);

const OPERATORS_USE_ITEM_VALUE = new Set([
  'near',
]);

const OPERATORS_USE_TYPE = new Set([
  'is during the current',
]);

const OPERATORS_USE_RANGE = new Set([
  'near',
]);

export default {
  components: {
    FormInput,
    BooleanInput,
    ConnectionInput,
    DateInput,
    MultipleChoiceInput,
    MultiSelect,
  },
  props: {
    field: {
      type: Object,
      default: () => null,
    },
    item: {
      type: Object,
      required: true,
    },
    defaultItem: {
      type: Function,
      default: () => {},
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    objectKey: {
      type: String,
      default: '',
    },
    availableFields: {
      type: Array,
      default: () => [],
    },
    valuesRestrictCalculatedFields: {
      type: Boolean,
      default: false,
    },
    valueCanBeField: {
      type: Boolean,
      default: false,
    },
    // validation rules currently have separate sets of operators
    // TODO:: combine and get the server to use validation operators for filters
    useValidationOperators: {
      type: Boolean,
      default: false,
    },
    // search overwrites this so multiple choice can use checkboxes/radios
    useSimplifiedInputs: {
      type: Boolean,
      default: true,
    },
    // can map a connection to a user role "To the logged-in Account"
    loggedInUserRoles: {
      type: Array,
      default: () => [],
    },
    // like adding and/or
    showBooleanOptions: {
      type: Boolean,
      default: false,
    },
    showTextField: {
      type: Boolean,
      default: false,
    },
    showField: {
      type: Boolean,
      default: true,
    },
    showOperator: {
      type: Boolean,
      default: true,
    },
    // showIsAny is an operator
    showIsAny: {
      type: Boolean,
      default: false,
    },
    booleanMatch: {
      type: String,
      default: 'and',
    },
    ruleType: {
      type: String,
      default: '',
    },

    setInputFormat: {
      type: Function,
      default: null,
    },
    isToolboxChild: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'connection', // from ConnectionInput component
    'update:modelValue',
    'update:item',
    'updateBooleanMatch',
  ],
  data() {
    return {
      inputType: null,
      localField: null,
      booleanMatchLocal: this.booleanMatch,
      specialDateRangeTypes: [
        'days',
        'weeks',
        'months',
        'years',
        'rolling weeks',
        'rolling months',
        'rolling years',
      ],
      currentDateRangeTypes: [
        'week',
        'month',
        'quarter',
        'year',
      ],
      sizeUnits: [
        'bytes',
        'kilobytes',
        'megabytes',
      ],
      rangeOptions: Array.from({
        length: 31,
      }, (value, index) => index + 1),
      nearRanges: [
        {
          value: 1,
          label: '1 mile',
        },
        {
          value: 5,
          label: '5 miles',
        },
        {
          value: 25,
          label: '25 miles',
        },
        {
          value: 50,
          label: '50 miles',
        },
        {
          value: 100,
          label: '100 miles',
        },
      ],
      daysOfWeek: [
        {
          value: 1,
          label: 'Monday',
        },
        {
          value: 2,
          label: 'Tuesday',
        },
        {
          value: 3,
          label: 'Wednesday',
        },
        {
          value: 4,
          label: 'Thursday',
        },
        {
          value: 5,
          label: 'Friday',
        },
        {
          value: 6,
          label: 'Saturday',
        },
        {
          value: 0,
          label: 'Sunday',
        },
      ],
    };
  },
  computed: {
    connectionValue: {
      /**
       * @returns {Array<{id: string} | Symbol>}
       */
      get() {
        const values = castArray(this.item.value || '');

        return values.map((itemValue) => {
          if (isString(itemValue) || isSymbol(itemValue)) {
            return itemValue;
          }

          return itemValue?.id;
        });
      },
      /**
       * @param {Array<{id: string, identifier: string}>} newValues
       */
      set(newValues) {
        const ids = newValues.map((value) => value.id);

        this.onItemUpdate('value', ids);
        this.$emit('update:modelValue', ids);
      },
    },
    fieldTypeIsMultiple() {
      return this.item.multi_type === 'many';
    },
    getFieldOptionsOptions() {
      const getFieldOptionsOptions = {};

      if (this.valuesRestrictCalculatedFields) {
        getFieldOptionsOptions.ignoreTypes = window.Knack.getCalculatedFieldTypes();
      }

      return getFieldOptionsOptions;
    },
    object() {
      return this.$store.getters.getObject(this.objectKey);
    },
    input() {
      const input = {
        format: this.localField.format,
        // Ensures all field types can properly render as a form input.
        type: this.localField.getFormInputType(),
      };

      if (this.setInputFormat) {
        input.format = this.setInputFormat(this.item, this.localField);
      }

      return input;
    },
    valueType() {
      const { Knack } = window;
      const types = {
        [Knack.config.BOOLEAN]: 'boolean',
        [Knack.config.MULTIPLE_CHOICE]: 'select',
        [Knack.config.USER_ROLES]: 'select',
        [Knack.config.DATE_TIME]: 'date',
        [Knack.config.CONNECTION]: 'connection',
      };

      return types[this.localField?.type] || 'text';
    },
    valueOptions() {
      // boolean
      if (this.localField.type === window.Knack.config.BOOLEAN) {
        return [
          {
            value: true,
            label: this.localField.getBooleanTrueLabel(),
          },
          {
            value: false,
            label: this.localField.getBooleanFalseLabel(),
          },
        ];
      }

      // user roles
      if (this.localField.type === window.Knack.config.USER_ROLES) {
        return this.$store.getters.roleObjects.map((object) => ({
          value: object.get('profile_key'),
          label: object.name,
        }));
      }

      // multiple choice
      const multipleChoiceOptions = this.localField.get('format').options.map((value) => ({
        value,
        label: value,
      }));

      const blankDefault = this.localField.format.default;

      if (!blankDefault || blankDefault === 'kn-blank') {
        const blankLabel = this.localField.format.blank;

        // The blank option is added here so that the resetValues() missing value logic functions properly.
        multipleChoiceOptions.unshift({
          value: '',
          label: blankLabel || '',
        });
      }

      return multipleChoiceOptions;
    },
    fieldOptions() {
      if (this.availableFields.length > 0) {
        return this.availableFields;
      }

      return this.object.getFieldOptions();
    },
    operators() {
      if (!this.localField) {
        return [];
      }

      const operators = this.useValidationOperators ? this.localField.getValidationOperators(this.ruleType) : this.localField.getRuleOperators(this.ruleType);

      // add a logged-in user operator?
      if (this.loggedInUserRoles.length && this.localField.isConnectedToUserRole()) {
        // connected object
        const connectedObject = this.localField.getConnectedObject();
        const profileKey = connectedObject.profile_key;

        if (profileKey === 'all_users' || this.loggedInUserRoles.includes(profileKey)) {
          operators.push({
            value: 'user',
            label: `is the logged-in ${connectedObject.inflections.singular}`,
          });
        }
      }

      if (this.showIsAny && operators.indexOf('is any') === -1) {
        operators.unshift('is any');
      }

      return operators;
    },
    isNotBlank() {
      // if the operator is hidden then it's never blank
      if (!this.showOperator) {
        return true;
      }

      if (FieldOperators.hideValueBasedOnOperator(this.item.operator)) {
        return false;
      }

      return true;
    },
    isSpecialValue() {
      return SPECIAL_VALUE_OPERATORS.has(this.item.operator);
    },
    isSpecialDateRange() {
      return SPECIAL_DATE_RANGE_OPERATORS.has(this.item.operator);
    },
    isSpecialSize() {
      return SPECIAL_SIZE_OPERATORS.has(this.item.operator);
    },
    shouldWrap() {
      // If this fieldListItem is not inside a toolbox should not wrap
      if (!this.isToolboxChild) {
        return false;
      }

      // If we are in the toolbox, but the toolbox is expanded, do not wrap
      if (this.$store.getters.isToolboxExpanded) {
        return false;
      }

      // If we are in the toolbox and it is not expanded, ensure the valueType is correct to wrap
      return WRAP_TYPES.has(this.valueType);
    },
    operatorUsesItemValue() {
      // only special values may ignore item.value
      if (!this.isSpecialValue) {
        return true;
      }

      if (this.isSpecialSize) {
        return true;
      }

      // these special operators also use item.value
      return OPERATORS_USE_ITEM_VALUE.has(this.item.operator);
    },
    operatorUsesType() {
      if (this.isSpecialDateRange) {
        return true;
      }

      return OPERATORS_USE_TYPE.has(this.item.operator);
    },
    operatorUsesRange() {
      if (this.isSpecialDateRange) {
        return true;
      }

      return OPERATORS_USE_RANGE.has(this.item.operator);
    },
    useSimpleValueInputs() {
      // if we have a complex value we can't use simple value inputs
      // i.e. for a multi-select dropdown we need to compare 'changed to' values
      // with potentially multiple values, this requires a non-simple input
      if (COMPLEX_INPUT_VALUE_OPERATORS.has(this.item.operator)) {
        return false;
      }

      // otherwise fall back to the prop
      return this.useSimplifiedInputs;
    },
  },
  watch: {
    booleanMatch(newVal) {
      this.booleanMatchLocal = newVal;
    },
    'item.field': function (newField, oldField) {
      if (newField === oldField) {
        return;
      }

      this.updateLocalField();
    },
    'item.multi_input': function (newVal) {
      if (!this.item.field || !this.localField || !newVal) {
        return;
      }

      this.localField.format.input = newVal;
      this.inputType = newVal;
    },
  },
  created() {
    this.updateLocalField(this.item?.value);
  },
  methods: {
    onItemUpdates(updates) {
      if (isEmpty(updates)) {
        return;
      }

      let updatedItem = cloneDeep(this.item);

      const updatedFields = {};
      updates.forEach(([fieldName, newValue]) => {
        updatedFields[fieldName] = true;

        set(updatedItem, fieldName, newValue);
      });

      if (updatedFields.operator) {
        updatedItem = this.onUpdateOperator(updatedItem);
      }

      this.$emit('update:item', updatedItem);
    },

    onItemUpdate(fieldName, newValue) {
      this.onItemUpdates([[fieldName, newValue]]);
    },

    updateLocalField(newValue) {
      const updates = [];

      if (this.valueCanBeField) {
        // Check if item has value_type, otherwise use default if there is one
        const defaultValueType = this.defaultItem().value_type;
        if (isNil(this.item.value_type) && !isNil(defaultValueType)) {
          updates.push(['value_type', defaultValueType]);
        }

        // Check if item has value_field, otherwise use default if there is one
        const defaultValueField = this.defaultItem().value_field;
        if (isNil(this.item.value_field) && !isNil(defaultValueField)) {
          updates.push(['value_field', defaultValueField]);
        }
      }

      // Check if item already has a field
      if (isNil(this.item.field)) {
        updates.push(['field', this.defaultItem().field]);
      } else {
        this.localField = this.getField();
        this.resetValues(newValue);
      }

      if (updates.length) {
        this.onItemUpdates(updates);
      }
    },

    /**
     * Clean up certain attributes when the operator is changed.
     *
     * @param {object} newItem - The item to update (after operator has changed).
     * @returns {object} - The object with the updates.
     */
    onUpdateOperator(newItem) {
      const newOperator = newItem.operator;
      const oldOperator = this.item.operator;

      log('item.operator watch |', newOperator, '|', oldOperator, '|', newItem.value);

      if (typeof oldOperator === 'undefined') {
        return newItem;
      }

      const updatedItem = cloneDeep(newItem);

      // delete the value from item but save it for restoring if needed later
      if (!this.operatorUsesItemValue) {
        delete updatedItem.value;
      }

      // restore the value if it doesn't exist on the item
      if (this.operatorUsesItemValue && typeof updatedItem.value === 'undefined') {
        updatedItem.value = '';
      }

      // delete unused attributes
      if (!this.operatorUsesType) {
        delete updatedItem.type;
      }

      if (!this.operatorUsesRange) {
        delete updatedItem.range;
      }

      // the following all set default values for special operators
      if (this.isSpecialDateRange) {
        updatedItem.range = 1;
        updatedItem.type = this.specialDateRangeTypes[0];
      }

      if (this.isSpecialSize) {
        updatedItem.unit = this.sizeUnits[0].value;
      } else {
        delete updatedItem.unit;
      }

      if (newOperator === 'is during the current') {
        updatedItem.type = this.currentDateRangeTypes[0];
      }

      if (newOperator === 'near') {
        updatedItem.range = this.nearRanges[0].value;
      }

      if (newOperator === 'is between days of the week') {
        updatedItem.from_date = this.daysOfWeek[0].value;
        updatedItem.to_date = this.daysOfWeek[4].value;
      } else {
        delete updatedItem.from_date;
        delete updatedItem.to_date;
      }

      if (newOperator === 'is a day of the week') {
        updatedItem.value = 0;
      }

      if (newOperator === 'is between dates') {
        updatedItem.value = {
          to_date: '',
          from_date: '',
        };
      }

      if (updatedItem.value === undefined) {
        updatedItem.value = '';
      }

      return updatedItem;
    },

    getField() {
      if (!this.item.field) {
        return undefined;
      }

      const fieldKey = this.item.field.split('-').pop();

      const field = this.field || this.$store.getters.getField(fieldKey);

      if (!field) {
        return undefined;
      }

      // override format options based on item
      if (field.type === 'connection') {
        // search items have format overrides
        if (this.item.multi_input) {
          field.format.input = this.item.multi_input;
        }
      }

      if (field.type === 'multiple_choice') {
        // booleans

        // search items have format overrides
        if (this.item.multi_input) {
          field.format.type = this.item.multi_input;
        }

        // search can be a multi select or single select
        if (field.format.type === 'chosen' && this.item?.multi_type === 'one') {
          field.format.type = 'select';
        }
      }

      this.inputType = field.format.input;

      return field;
    },
    getOperatorValue(operator) {
      if (operator.value) {
        return operator.value;
      }

      return operator;
    },

    getOperatorLabel(operator) {
      if (operator.label) {
        return operator.label;
      }

      return operator;
    },

    resetValues(newValue = null) {
      const updates = [];

      // change operator?
      const operatorExists = this.operators.find(
        (option) => (option === this.item.operator || option?.value === this.item.operator),
      );


      if (this.valueType === 'boolean') {
        // Make sure the value is actually a boolean.
        if (this.item.value !== true && this.item.value !== false) {
          updates.push([
            'value',
            (newValue !== null) ? Boolean(newValue) : true,
          ]);
        }
      } else if (this.valueType === 'select' && this.valueOptions.length) {
        // multiple choice, check if value is compatible
        const hasValidValue = this.valueOptions.find((option) => option.value === this.item.value);

        // if value does not exist in these options, then set it to the default or the first.
        if (!hasValidValue) {
          let defaultOption = null;

          if (this.localField.format.default) {
            // The blank option value should always be an empty string.
            // It should not be `kn-blank` like the default value.
            defaultOption = this.valueOptions.find((option) => option.value === '');
          }

          // Set the item value to the default option value or just choose the first option's value.
          updates.push([
            'value',
            (defaultOption) ? defaultOption.value : this.valueOptions[0].value,
          ]);
        }
      } else if (this.item.value === undefined) {
        // If no value is set then define a starting value.
        updates.push([
          'value',
          (newValue !== null) ? newValue : '',
        ]);
      }

      /*
        // TODO: this isn't needed if we're resetting all values above, but should keep if we add some optionality to retain values.
        // Reset value if this is a simple field (e.g. text, number) and the value is an object.
        // This prevents seeing [object object] when converting from a complex field like date
        const valueIsComplex = (typeof this.item.value === `object` || typeof this.item.value === `array`)
        if (this.localField.isSimpleValue() && valueIsComplex) {

          newItem.value = ``
        }
        */

      this.onItemUpdates(updates);
    },

    onUpdateBooleanMatch() {
      this.$emit('updateBooleanMatch', this.booleanMatchLocal);
    },

    // Set properties necessary on these methods because conditional rules from v2 don't necessarily
    // have value_field or value_type but we still want them to be reactive to changes
    toggleValueToField() {
      const updates = [
        ['value_type', 'field'],
      ];

      if (!this.item.value_field) {
        const fieldKey = this.object?.fields?.[0]?.key || '';

        updates.push(['value_field', fieldKey]);
      }

      this.onItemUpdates(updates);
    },

    onSelectFieldType(event) {
      const newSelected = event.target.value;
      if (newSelected === 'custom') {
        this.onItemUpdate('value_type', 'custom');
      } else {
        this.toggleValueToField();
      }
    },

    operatorIsDisabled(operator) {
      switch (this.localField.type) {
        case 'address':

          // Disable near operator if geocoding is not enabled on field
          if (operator.value === 'near' && this.localField.get('format').enable_geocoding !== true) {
            return true;
          }

          return false;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss">
.field-list-item {
  flex-flow: wrap;
  min-width: 0;

  & > * {
    margin-right: .25rem;
    min-width: 0;
    max-width: fit-content;
    flex: 1;
    margin-bottom: .25rem;
  > span {
    display: block;
    margin-top: 6px;
    }
  }

  .field-list-popover {
    height: 2rem;
    width: 2rem;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  & > .min-label {
    flex: 0 1 auto;
    min-width: 0;
  }

  & > br {
    min-width: 100%;
    height: 0;
    content: '';
  }
}

.field-list .field-list-item.field-list-item-wrap {
  justify-content: space-between;

  > * {
    margin-right: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 49%;
    max-width: none;

      &:nth-child(3) {
        flex-basis: 100%;
        padding-right: 0;
      }
    }
  }

.toolbox-body .field-list-item {
  > * {
    min-width: 35%;
    max-width: 100%;
  }
}
</style>
