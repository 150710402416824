<template>
  <div
    class="rules-list-wrapper"
    :class="{ 'pb-10': isToolboxSaveRendered }"
  >
    <GroupList
      v-model:items="localRules"
      :class="`record-rules ${groupListClass}`"
      class="mt-4"
      :group-list-item-classes="groupListItemClasses"
      :item-title="itemTitle"
      :is-image-or-file-field="isImageOrFileField"
    >
      <template #default="{item, itemIndex}">
        <RuleItem
          :item="item"
          :item-index="itemIndex"
          :input-field-options="inputFieldOptions"
          :allow-connection-option="allowConnectionOption"
          :allow-record-actions="allowRecordActions"
          :allow-email-action="allowEmailAction"
          :allow-email-action-basic="allowEmailActionBasic"
          :criteria-values-can-be-fields="criteriaValuesCanBeFields"
          :rule-type="ruleType"
          :show-criteria-title="showCriteriaTitle"
          :show-field-options="showFieldOptions"
          :show-action-selection="showActionSelection"
          :field-default="fieldDefault"
        >
          <template #no-criteria>
            <slot name="no-criteria" />
          </template>
          <template #criteria-title>
            <slot name="criteria-title" />
          </template>
        </RuleItem>
      </template>
    </GroupList>
    <div>
      <a
        class="button small green-gray h-10 w-fit p-3 rounded-lg border border-solid border-default bg-white leading-4 text-emphasis text-base font-medium hover:bg-brand-50 hover:border-brand-600 group"
        @click="onAddListItem"
      >
        <Icon
          class="text-default fill-current h-4 w-4 mr-1 group-hover:text-brand-400"
          type="plus-thin"
        /> Add Rule
      </a>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import GroupList from '@/components/ui/lists/GroupList';
import RuleItem from '@/components/rules/RuleItem';
import { RuleType } from '@/constants/rules';

export default {
  name: 'RulesList',
  components: {
    Icon,
    GroupList,
    RuleItem,
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    fieldDefault: {
      type: Object,
      default: () => ({}),
    },
    inputFieldOptions: {
      type: Array,
      default: () => [],
    },
    allowConnectionOption: {
      type: Boolean,
      default: true,
    },
    allowRecordActions: {
      type: Boolean,
      default: true,
    },
    allowEmailAction: {
      type: Boolean,
      default: false,
    },
    allowEmailActionBasic: {
      type: Boolean,
      default: false,
    },
    criteriaValuesCanBeFields: {
      type: Boolean,
      default: false,
    },
    showFieldOptions: {
      type: Boolean,
      default: true,
    },
    showActionSelection: {
      type: Boolean,
      default: true,
    },
    showCriteriaTitle: {
      type: Boolean,
      default: true,
    },
    itemTitle: {
      type: String,
      default: 'Record Rule',
    },
    groupListClass: {
      type: String,
      default: 'small',
    },
    ruleType: {
      type: String,
      default: RuleType.Record,
    },
    groupListItemClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['update:rules'],
  computed: {
    localRules: {

      get() {
        return this.rules;
      },

      set(newVal) {
        this.$emit('update:rules', newVal);
      },
    },
    object() {
      if (this.$store.getters.activeView?.source?.object) {
        return this.$store.getters.getObject(this.$store.getters.activeView.source.object);
      }

      if (this.$store.getters.activeObject) {
        return this.$store.getters.activeObject;
      }

      return {};
    },

    objectConnections() {
      const conns = [];

      this.object.conns.forEach((conn) => {
        const connectionObject = this.$store.getters.getObject(conn.object);

        // you can't insert ecommerce
        if (!connectionObject || connectionObject.get('ecommerce')) {
          return;
        }

        conns.push({
          key: conn.key,
          value: `${conn.object}.${conn.key}`,
          label: `${connectionObject.inflections.singular} connected to this record (${connectionObject.inflections.singular} > ${conn.name})`,
          object: conn.object,
          name: conn.name,
          has: conn.has,
        });
      });

      return conns;
    },
    hasConnections() {
      return this.objectConnections.length > 0;
    },
    isToolboxSaveRendered() {
      const { getters } = this.$store;

      return getters.viewHasActiveUpdates || getters.pageHasActiveUpdates;
    },
    isImageOrFileField() {
      return this.fieldDefault.type === 'image' || this.fieldDefault.type === 'file';
    },
  },
  methods: {
    getValuesObject(action, connectionKey) {
      if (action === 'record') {
        return this.object;
      }

      if (!connectionKey) {
        return this.object;
      }

      // if not record, then it's a connection
      const connectedObjectKey = connectionKey.split('.')[0];

      return this.$store.getters.getObject(connectedObjectKey);
    },
    getConnectionActions(action) {
      action = action || 'connection'; // connection | insert

      const options = [];

      this.objectConnections.forEach((conn) => {
        let label;

        if (action === 'insert') {
          label = `A new ${conn.label}`;
        } else {
          label = `${(conn.has === 'one') ? 'The' : 'Each'} ${conn.label}`;
        }

        options.push({
          value: conn.value,
          label,
        });
      });

      return options;
    },
    onAddListItem() {
      const action = (this.allowRecordActions) ? 'record' : 'email';

      // this is for tracking events to intercom, as the Save button is rendered in any toolbox within the builder
      // so we can check if a record or an email rule was added to trigger only in these cases the intercom event
      // after clicking save or going back, the localStorage is cleared
      localStorage.setItem(`${action}_rule_added`, true);

      const field = (this.fieldDefault)
        ? this.fieldDefault.key
        : this.object?.getMutableFields?.()?.[0]?.key;

      let criteria = [];

      if (this.isImageOrFileField) {
        criteria = [
          {
            field: field.key,
            operator: 'is blank',
            value: '',
          },
        ];
      }

      const rules = [...this.rules];
      rules.push({
        action,
        connection: this.objectConnections?.[0]?.value ?? null,
        criteria,
        values: [
          {
            field,
            type: 'value',
            input: '',
            connection_field: '',
            value: '',
          },
        ],
        email: {},
      });

      this.localRules = rules;
    },
  },
};
</script>

<style lang="scss">
.form-emails textarea {
  min-height: 200px !important
}
</style>
